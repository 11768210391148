import React from "react";
import eSkillzHappyE from "../brandingImages/happy-e.png";
import eSkillzLogo from "../brandingImages/eskillz-logo.png";
import facebookLogo from "../brandingImages/facebook-logo.png";
import instagramLogo from "../brandingImages/instagram-logo.png";
import linkedInLogo from "../brandingImages/linkedIn-logo.png";
import xLogo from "../brandingImages/x-logo.png";

function EmailSignatureTwo(user, phone, ext) {
  return (
    <>
      <table cellSpacing="0" width="100%" cellPadding="0" border="0">
        <tbody>
          <tr>
            <td width="80" valign="top">
              <img src={eSkillzHappyE} width="60" />
            </td>
            <td style={{ verticalAlign: "top" }} valign="top">
              <table
                cellSpacing="0"
                cellPadding="0"
                border="0"
                style={{
                  lineHeight: "18px",
                  fontFamily: "Arial, Geneva, sans-serif",
                  fontSize: "14px",
                  color: "#434043",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <span
                        style={{
                          font: "16px Arial Black, Arial, Geneva, sans-serif",
                          color: "#0a0094",
                          fontWeight: "bold",
                        }}
                      >
                        {user.user.displayName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        style={{
                          fontFamily: "Arial Black, Arial, Geneva, sans-serif",
                          color: "#434043",
                          fontSize: "14px",
                          lineHeight: "14px",
                        }}
                      >
                        {user.user.jobTitle}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "1px",
                        height: "5px",
                        lineHeight: "1px",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        {" "}
                        <a
                          href={"mailto:" + user.user.mail}
                          target="_blank"
                          style={{
                            fontFamily: "Arial, Geneva, sans-serif",
                            color: "#0a0094",
                            textDecoration: "none",
                          }}
                        >
                          {user.user.mail}
                        </a>{" "}
                      </span>
                      &nbsp;
                      <span
                        style={{
                          fontFamily: "Arial, Geneva, sans-serif",
                          color: "#85be00",
                        }}
                      >
                        •
                      </span>
                      &nbsp;
                      <span style={{ fontFamily: "Arial, Geneva, sans-serif" }}>
                        {" "}
                        <a
                          href={"https://eskillz.com"}
                          target="_blank"
                          style={{
                            fontFamily: "Arial, Geneva, sans-serif",
                            color: "#0a0094",
                            textDecoration: "none",
                          }}
                        >
                          eSkillz.com
                        </a>{" "}
                      </span>
                    </td>
                  </tr>
                  {createPhoneSection(user)}
                  <tr>
                    <td
                      style={{
                        fontSize: "1px",
                        height: "8px",
                        lineHeight: "1px",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                          <tr>
                            <td width="116">
                              {" "}
                              <a href={`https://eskillz.com`} target="_blank">
                                <img
                                  border="0"
                                  width="116"
                                  src={eSkillzLogo}
                                  alt="eSkillz Logo"
                                ></img>
                              </a>{" "}
                            </td>
                            <td
                              width="10"
                              style={{ borderRight: "1px solid #63cdd4" }}
                            >
                              &nbsp;
                            </td>
                            <td width="12">&nbsp;</td>
                            <td
                              width="21"
                              height="21"
                              style={{ paddingLeft: "5px" }}
                            >
                              {" "}
                              <a
                                href={`https://www.linkedin.com/company/eskillz/`}
                                target="_blank"
                              >
                                <img
                                  src={linkedInLogo}
                                  alt="LinkedIn"
                                  style={{ border: "0" }}
                                  width="21"
                                  height="21"
                                ></img>
                              </a>{" "}
                            </td>
                            <td width="21" style={{ paddingLeft: "5px" }}>
                              <a
                                href={`https://www.instagram.com/eskillzcorp`}
                                target="_blank"
                              >
                                <img
                                  src={instagramLogo}
                                  alt="Instagram"
                                  style={{ border: "0" }}
                                  width="21"
                                  height="21"
                                ></img>
                              </a>{" "}
                            </td>
                            <td width="21" style={{ paddingLeft: "5px" }}>
                              <a
                                href={`https://www.facebook.com/EskillzCorp/`}
                                target="_blank"
                              >
                                <img
                                  src={facebookLogo}
                                  alt="Facebook"
                                  style={{ border: "0" }}
                                  width="21"
                                  height="21"
                                ></img>
                              </a>{" "}
                            </td>
                            <td width="21" style={{ paddingLeft: "5px" }}>
                              <a
                                href={`https://twitter.com/eskillzcorp`}
                                target="_blank"
                              >
                                <img
                                  src={xLogo}
                                  alt="Twitter"
                                  style={{ border: "0" }}
                                  width="21"
                                  height="21"
                                ></img>
                              </a>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

function createPhoneSection(user) {
  if (user.phone !== "") {
    return (
      <>
        <tr>
          <td>
            <span style={{ fontFamily: "Arial, Geneva, sans-serif" }}>d:</span>
            <span>
              {" "}
              <a
                href={`tel:${user.phone}`}
                target="_blank"
                style={{
                  fontFamily: "Arial, Geneva, sans-serif",
                  color: "#434043",
                  textDecoration: "none",
                }}
              >
                {user.phone}
              </a>{" "}
            </span>
            &nbsp;
            <span
              style={{
                fontFamily: "Arial, Geneva, sans-serif",
                color: "#85be00",
              }}
            >
              •
            </span>
            &nbsp;
            <span style={{ fontFamily: "Arial, Geneva, sans-serif" }}>o:</span>
            <span>
              {" "}
              <a
                href={`tel:8004346418`}
                target="_blank"
                style={{
                  fontFamily: "Arial, Geneva, sans-serif",
                  color: "#434043",
                  textDecoration: "none",
                }}
              >
                800-434-6418 {user.ext !== "" ? `x: ${user.ext}` : ""}
              </a>{" "}
            </span>
          </td>
        </tr>
      </>
    );
  }
  return (
    <>
      <tr>
        <td>
          <span style={{ fontFamily: "Arial, Geneva, sans-serif" }}>o:</span>
          <span>
            {" "}
            <a
              href={`tel:8004346418`}
              target="_blank"
              style={{
                fontFamily: "Arial, Geneva, sans-serif",
                color: "#434043",
                textDecoration: "none",
              }}
            >
              800-434-6418 {user.ext !== "" ? `x: ${user.ext}` : ""}
            </a>{" "}
          </span>
        </td>
      </tr>
    </>
  );
}
export default EmailSignatureTwo;
