import { Providers } from "@microsoft/mgt-element";
import { Login } from "@microsoft/mgt-react";
import eSkillzLogo from "../brandingImages/eskillz-logo.png";

function LogIn({ setUser }) {
  return (
    <>
      <div className="App">
        <header className="App-header">
          <img
            src={eSkillzLogo}
            className="App-logo"
            alt="logo"
            width="200px"
          />
          <h1>eSkillz Signature Generator App</h1>
        </header>
        <Login
          loginCompleted={(e) => {
            Providers.globalProvider.graph.client
              .api("me")
              .version("beta")
              .get()
              .then((gotMe) => {
                setUser(gotMe);
              });
          }}
          logoutCompleted={(e) => {}}
        />
      </div>
    </>
  );
}

export default LogIn;
