import React, { useState, useEffect } from "react";
import EmailSignature from "./EmailSignature";

function SignatureGenerator({ user }) {
  const [showDirectPhone, setShowDirectPhone] = useState(false);
  const [showDirectExtension, setShowDirectExtension] = useState(false);
  const [directPhone, setDirectPhone] = useState(user.mobilePhone);
  const [directExtension, setDirectExtension] = useState("");
  const [generatedMobileSignature, setGeneratedMobileSignature] = useState("");

  // Function to generate the signature
  useEffect(() => {
    const generateSignature = () => {
      let htmlSignature =
        '<table border="0" cellpadding="0" cellspacing="0" width="100%"><tr><td style="width: 2px; background-color: #d7df23; vertical-align: "top" valign="top"></td><td style="width: 15px; vertical-align: "top" valign="top"></td><td style="vertical-align: "top" valign="top"><div style="display:block;line-height: 15px;font-family: Arial, Geneva, sans-serif;color: #4d4d4f;font-size:12px;">';

      htmlSignature += `<div style="font-size: 13px;font-weight: bold;text-transform: uppercase;"><strong>${user.givenName} ${user.surname}</strong></div>`;
      htmlSignature += `<div>${user.jobTitle}, ${user.department}</div>`;

      htmlSignature +=
        '<div style="line-height: 7px; font-size: 6px;">&nbsp;</div>';

      htmlSignature += `<div><a href="${user.mail}" style="color:#007fae; text-decoration: underline;" target="_blank">${user.mail}</a></div>`;

      // Initialize the phone line
      let phoneLine = "";

      if (showDirectPhone && showDirectExtension) {
        // Both direct phone and extension are present
        phoneLine = `d: ${directPhone}  x${directExtension}`;
      } else if (showDirectPhone) {
        // Only direct phone is present
        phoneLine = `d: ${directPhone}`;
      }

      if (phoneLine) {
        htmlSignature += `<div>${phoneLine}  <span style="color:#d7df23;">//</span>  `;
      } else {
        htmlSignature += "<div>";
      }

      htmlSignature += "o: 800-434-6418</div>";

      htmlSignature +=
        '<a href="https://eskillz.com/" target="_blank" style="color:#007fae; text-decoration: underline;">eSkillz.com</a>';

      htmlSignature += "<div>1717 Pennsylvania Avenue NW, Suite 1025</div>";
      htmlSignature += "<div>Washington, D.C. 20006</div>";

      // social icons
      htmlSignature +=
        '<div><a href="https://www.linkedin.com/company/eskillz/" target="_blank"><img alt="LinkedIn Logo" border="0" height="21" src="https://clientresources.eskillz.com/EmailSignatures/images/linked-in-28pxSquare-with-padding.png" style="border: 0; color: #007fae; width: 21px; height: 21px;" width="21"></a><a href="https://www.facebook.com/EskillzCorp/"><img alt="Facebook Logo" border="0" height="21" src="https://clientresources.eskillz.com/EmailSignatures/images/facebook-28pxSquare-with-padding.png" style="border: 0; color: #007fae; width: 21px; height: 21px;" width="21"></a><a href="https://twitter.com/eskillzcorp"><img alt="Twitter Logo" border="0" height="21" src="https://clientresources.eskillz.com/EmailSignatures/images/twitter-28pxSquare-with-padding.png" style="border: 0; color: #007fae; width: 21px; height: 21px;" width="21"></a></div></div></td></tr></table>';

      // eSkillz logo
      htmlSignature +=
        '<div><a href="https://eskillz.com/" target="_blank"><img alt="eSkillz Logo" border="0" height="43" src="https://clientresources.eskillz.com/EmailSignatures/images/logo_155pxHigh-with-padding.png" style="width:116px; height: 43px" width="116"></a></div>';

      // Generate the mobile version of the signature
      let mobileSignature =
        '<div style="display:block;line-height: 15px;font-family: Arial, Geneva, sans-serif;font-size:12px;">';
      mobileSignature += `<span style="font-size: 13px;font-weight: bold;text-transform: uppercase;"><strong>${user.givenName} ${user.surname}</strong></span><br/>`;
      mobileSignature += `${user.jobTitle}<br/>`;

      let phoneTextLine = "";

      if (showDirectPhone) {
        phoneTextLine = `d: ${directPhone}`;
      }

      if (phoneTextLine) {
        mobileSignature += `${phoneTextLine}  //  `;
      }
      if (showDirectExtension) {
        mobileSignature += `o: 800-434-6418 x${directExtension}<br/>`;
      } else {
        mobileSignature += `o: 800-434-6418<br/>`;
      }
      mobileSignature += "eSkillz.com<br/>";
      mobileSignature += "1717 Pennsylvania Avenue NW, Suite 1025<br/>";
      mobileSignature += "Washington, D.C. 20006<br/>";
      mobileSignature += "</div>";

      return { htmlSignature, mobileSignature };
    };

    const signatures = generateSignature();
    setGeneratedMobileSignature(signatures.mobileSignature);
  }, [
    user,
    showDirectPhone,
    showDirectExtension,
    directPhone,
    directExtension,
  ]);

  // Function to copy the signature to clipboard
  const copySignatureToClipboard = (signatureType, signatureTitle) => {
    const signatureElement = document.getElementById(signatureType);
    if (signatureElement) {
      const range = document.createRange();
      range.selectNodeContents(signatureElement);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        // Execute copy command
        document.execCommand("copy");
        alert(`${signatureTitle} signature copied to clipboard!`);
      } catch (error) {
        console.error("Copy failed: ", error);
      }

      // Clear the selection
      selection.removeAllRanges();
    }
  };
  return (
    <div className="signature-container">
      <h1 className="text-center">Welcome to eSkillz Signature Generator!</h1>
      <div className="checkbox-container">
        {/* Render input fields, checkboxes */}
        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={showDirectPhone}
              onChange={() => setShowDirectPhone(!showDirectPhone)}
            />
            Include direct phone number
          </label>
          {showDirectPhone && (
            <input
              type="text"
              className="form-control"
              placeholder="Enter Phone Number"
              value={directPhone}
              onChange={(e) => setDirectPhone(e.target.value)}
            />
          )}
        </div>
        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={showDirectExtension}
              onChange={() => setShowDirectExtension(!showDirectExtension)}
            />
            Include direct phone extension
          </label>
          {showDirectExtension && (
            <input
              type="text"
              className="form-control"
              placeholder="Enter Phone Extension"
              value={directExtension}
              onChange={(e) => setDirectExtension(e.target.value)}
            />
          )}
        </div>
      </div>

      <div className="signatures">
        <div className="signature-html">
          <h2>Generated Signature (HTML)</h2>
          {/* Display the generated HTML signature */}
          <div id="generatedHtmlSignature">
            <EmailSignature
              user={user}
              phone={showDirectPhone ? directPhone : ""}
              ext={showDirectExtension ? directExtension : ""}
            />
          </div>
          <button
            onClick={() =>
              copySignatureToClipboard("generatedHtmlSignature", "HTML")
            }
          >
            Copy HTML Signature
          </button>
        </div>

        <div className="signature-mobile">
          <h2>Generated Signature (Mobile)</h2>
          {/* Display the mobile version of the generated signature */}
          <div
            id="generatedMobileSignature"
            dangerouslySetInnerHTML={{ __html: generatedMobileSignature }}
          />
          <button
            onClick={() =>
              copySignatureToClipboard("generatedMobileSignature", "Mobile")
            }
          >
            Copy Mobile Signature
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignatureGenerator;
